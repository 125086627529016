import {
  type Blot,
  type BlotConstructor,
  blotList,
  blotNodeMap,
  Commands,
  type DeltaInsertAttributes,
  descendantBlot,
  descendantBlots,
  type EditorOptions,
  GenerateStyleEntity,
  isScopeExactLineBlot,
  type Numbered,
  type ParentBlot,
  ScheduleType,
  Scroll,
  type Stages
} from '@avvoka/editor'
import {getCurrentLocale, useLocalizationStore} from "@stores/features/localization.store";
import {StoreMode} from "@stores/utils";
import {getActivePinia} from "pinia";
import {generateBlotStyles} from "~/features/editor/styles/css-convertor";
import type {StoreWithStyles} from "~/features/editor/styles/index";

export interface CustomGenerateStyleEntity {
  style: string
  custom?: boolean
}

const styleMap = new Map<string, HTMLElement>()
const lineFormats = blotList.filter(isScopeExactLineBlot).map((b) => b.tagName)

/**
 * Returns a measurement function to convert pixels (px) to millimeters (mm).
 */
export const getMeasurementNode = () => {
  const ratioNode = document.createElement('div')
  ratioNode.style.display = 'none'
  ratioNode.style.height = '100mm'
  ratioNode.classList.add('px-to-mm-ratio')
  document.body.appendChild(ratioNode)

  return {
    PX_TO_MM: (px: number) =>
      px / (parseFloat(window.getComputedStyle(ratioNode).height) / 100)
  }
}

export const getEditorOptionsMockup = async () => {
  const mode = EditorFactory.mainOptional.mapOr(
    (editor) => editor.options.mode,
    'document'
  )
  const rtl = false

  const locale = await getCurrentLocale();
  const localizationStore = useLocalizationStore(getActivePinia())
  await localizationStore.hydrate({
    locale: locale
  }, ['localized_numbers'])

  return { mode, rtl, localizedNumbers: localizationStore.localizedNumbers } as unknown as EditorOptions
}

export const getStagesMockup = () => {
  return { schedule() {} } as unknown as Stages
}
/**
 * Create a mockup of a Blot object based on the given element and parent.
 * The blot will be created with all attributes and children.
 * Use this to generate styles for a given element in recalculating styles.
 *
 * @param element - The HTML element to create a mockup of.
 * @param editorOptions - Editor Options
 * @param parent - The parent blot of the element.
 * @returns - The created Blot mockup, or null if no matching blot class found.
 */
export const createBlotMockup = (
  element: HTMLElement,
  editorOptions: EditorOptions,
  parent?: ParentBlot,
): Blot | null => {
  const fixAttributeName = (name: string) => {
    if (name === 'pattern') return 'data-mask-pattern'
    return name
  }

  let nodeName = element.nodeName
  if (nodeName === '#text') nodeName = 'TEXT'
  if (nodeName === '#comment')
    nodeName = element.nodeValue?.split(' ')[0].toUpperCase() || 'COMMENT'

  const blotClass = blotNodeMap.get(nodeName)
  if (blotClass.isSome()) {
    const constr = blotClass.get() as unknown as BlotConstructor
    const blot = new constr(
      element as unknown as Node,
      editorOptions
    )
    Array.from(element.attributes ?? []).forEach((val) =>
      blot.setDirectAttribute(
        fixAttributeName(val.nodeName),
        val.nodeValue,
        true
      )
    )
    blot.parent = parent

    // parse all children
    if ('children' in blot) {
      const children = Array.from(element.childNodes ?? []).map((child) =>
        createBlotMockup(child as HTMLElement, editorOptions, blot as ParentBlot)
      )
      blot.children = children.filter((child): child is Blot => child !== null)
    }

    return blot
  }

  return null
}

const createBlotsMockup = (formats: DeltaInsertAttributes, editorOptions: EditorOptions): Blot[] => {
  const blots = Object.keys(formats)
    .map((blotName) => {
      const blot = blotList.find((b) => b.blotName === blotName)
      if (!blot) {
        console.warn(
          `The blot ${blotName} was not found. Please check the updates are not running otherwise this is a bug.`
        )
        return null
      }

      const node = document.createElement(blot.tagName)
      Object.entries(formats[blot.blotName]).forEach(([key, value]) => {
        // eslint-disable-next-line @typescript-eslint/no-base-to-string
        node.setAttribute(`${key}`, String(value))
      })

      return createBlotMockup(node, editorOptions)
    })

  return blots.filter((result): result is Blot => result !== null)
}


export const createGlobalStyles = async (store: StoreWithStyles, numberings: Numbered[], editorOptions: EditorOptions) => {
  // We need access to the global styles
  if (store.hydratedData || store.storeMode == StoreMode.NewData) {
    const styles = store.docxSettings.formats
    let held = 0
    for (const styleKey in styles) {
      const style = styles[styleKey]
      const definition = style.definition as DeltaInsertAttributes
      const blots = createBlotsMockup(definition, editorOptions)
      const commands = new Commands()

      // Let editor create the styling entities but we will use them for global styles (by prepending the stylename)
      generateBlotStyles(blots, commands)

      // Transform the styling entities into global styles
      transformStyleEntitiesIntoGlobalStyles(
        styleKey,
        commands.spawnBuffer as GenerateStyleEntity[],
        store.defaultStyle.key,
        numberings
      )

      if(++held % 100 === 0) {
        await new Promise((resolve) => setTimeout(resolve, 0))
      }
    }
  }
}
export const createBlotsMockupFromElements = (
  editorOptions: EditorOptions,
  elements: HTMLElement[] = Array.from(
    document.querySelectorAll<HTMLElement>('.avv-container > *')
  ),
): Blot[] => {
  const blots = elements.map((el) => createBlotMockup(el, editorOptions))
  return blots.filter((blot): blot is Blot => blot !== null)
}
export const getStyleParents = (
  store: StoreWithStyles,
  style: Backend.Models.TemplateVersion.Style
) => {
  const parents: string[] = []
  if (style && style.parent) {
    parents.push(style.parent)
    parents.push(
      ...(getStyleParents(
        store,
        store.docxSettings.formats[style.parent]
      ) as string[])
    )
  }
  return parents
    .map((p) => store.docxSettings.formats[p])
    .filter((p) => p != null)
}
const transformStyleEntitiesIntoGlobalStyles = (styleName: string, styleEntities: CustomGenerateStyleEntity[], defaultStyleKey: string, numberings: Numbered[]) => {
  const existingStyles = Array.from(document.head.querySelectorAll('style[generated]')).reduce<Record<string, HTMLElement>>((acc, style) => {
    const name = style.getAttribute('style') as string | undefined
    if (name) {
      acc[name] = style as HTMLElement
    }
    return acc
  }, {})

  // Generate the style element if it doesn't exist
  let styleElement = styleMap.get(styleName)
  if (!styleElement) {
    if (existingStyles[styleName]) {
      styleElement = existingStyles[styleName]
    } else {
      styleElement = document.createElement('style')
      styleElement.setAttribute('generated', styleName)
      styleElement.setAttribute('style', styleName)
      document.head.appendChild(styleElement)
    }

    styleMap.set(styleName, styleElement)
  }

  const serializeName = (name: string) => {
    return name.replace(/[\[\]]/g, '\\$1')
  }

  const styleIdentifier = `${lineFormats
    .flatMap((tag) => {
      if (styleName === defaultStyleKey) {
        return [`.avv-editor .avv-container ${tag}:not([data-avv-style])`, `.avv-editor .avv-container ${tag}[data-avv-style="${serializeName(styleName)}"]`]
      } else {
        return `.avv-editor .avv-container ${tag}[data-avv-style="${serializeName(styleName)}"]`
      }
    })
    .join(', ')}, .avv-styles\\:${serializeName(styleName)} `

  let newHtml = `${styleIdentifier} { ${styleEntities.reduce((result, {style, custom}) => {
    if (custom) return result
    return (result + style
      .substring(style.indexOf('{') + 1, style.lastIndexOf('}'))
      .replace(' !important', '') + '\n')
  }, '')} }`

  // Map the lines to css selectors with the given style name
  const innerIdent = lineFormats
    .flatMap((line) => {
      if (styleName === defaultStyleKey) {
        return [`${line}:not([data-avv-style])`, `${line}[data-avv-style="${styleName}"]`]
      } else {
        return `${line}[data-avv-style="${styleName}"]`
      }
    })
    .join(', ')

  if (styleName === defaultStyleKey) {
    const fontSizeEntity = styleEntities.find((e) => e.style.startsWith('{font-size:'))
    if (fontSizeEntity) {
      const fontSize = fontSizeEntity.style.substring(fontSizeEntity.style.indexOf('{') + 1, fontSizeEntity.style.lastIndexOf('}'))
      // Add font-size for numbered lists
      styleEntities.push({
        style: `avv-numbered:has($IDENT$)::before {${fontSize}}`, custom: true
      })
    }
  }

  // All styles that are generating custom css
  const custom = styleEntities.filter((e) => e.custom)

  newHtml += // Add a new line to separate the custom styles
    '\n' + // Add the custom styles
    custom.map((item) => item.style.replace('$IDENT$', innerIdent)).join('\n')

  // Find all numberings and update their font-size on the fly
  numberings.forEach((numbering) => {
    const line = descendantBlot(numbering, 0, Infinity, isScopeExactLineBlot, false)
    if (line) {
      const lineStyle = line.attributesOptional['data-avv-style'].getOr(defaultStyleKey)
      if (lineStyle == styleName) {
        numbering.schedule(ScheduleType.UPDATE_NUMBERING_WIDTH, {
          blot: numbering
        })
      }
    }
  })

  if(styleElement.innerHTML.replaceAll('[\n\s]', '') !== newHtml.replaceAll('[\n\s]', '')) {
    styleElement.innerHTML = newHtml
  }
}
export const generateLineHeights = (store: StoreWithStyles, commands: Commands, scroll: Scroll) => {
  const defaultStyle = store.defaultStyle
  if (!defaultStyle) return

  const lines = descendantBlots(scroll, 0, Infinity, isScopeExactLineBlot)
  lines.forEach((line) => {
    const lineHeightOpt = line.attributesOptional['data-avv-line-height']
    const lineRuleOpt = line.attributesOptional['data-docx-line-rule']
    if (lineHeightOpt.isPresent() && lineRuleOpt.isPresent()) {
      const lineRule = lineRuleOpt.get()
      const lineHeight = lineHeightOpt.get()

      if (lineRule === 'atLeast') {
        // Find parent style with line-height
        const lineStyleKey = line.attributesOptional['data-avv-style'].getOr(defaultStyle.key)
        const style = store.docxSettings.formats[lineStyleKey] ?? defaultStyle
        const parents = getStyleParents(store, style)
        const currentLineHeight = parents.find((p) => p.definition?.block?.['data-avv-line-height'] && p.definition?.block?.['data-avv-line-height'] != lineHeight)?.definition?.block?.['data-avv-line-height'] ?? defaultStyle.definition?.block?.['data-avv-line-height'] ?? '1.15'

        commands.spawn(new GenerateStyleEntity(`.avv-container { p[data-avv-line-height="${lineHeight}"][data-docx-line-rule="${lineRule}"] { line-height: clamp(${lineHeight}mm, ${currentLineHeight}mm, 100mm) !important; } }`, line))
      }
    }
  })
}
